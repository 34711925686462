import type { CartCookie } from '~/types/reservation'

/*
 * Make sure to call this once per component
 */
export default function useCartCookie() {
    const { $config } = useNuxtApp()

    // cookie Value isn't reactive when maxAge or expires are set
    // https://github.com/nuxt/nuxt/issues/24856
    const cartCookie = useCookie<CartCookie | undefined>('cart', {
        maxAge: 3600 * 24 * 7, // set cart cookie to expire in 7 days
        watch: true,
        domain: $config.public.cookie.domain,
        secure: $config.public.cookie.secure,
        default: () => undefined as CartCookie | undefined,
    })

    // Use state for update cookie
    const cartCookieProxy = useState('cart_proxy', () => cartCookie.value)

    watch(
        cartCookieProxy,
        (state) => {
            cartCookie.value = state
        },
        { deep: true },
    )

    return cartCookieProxy
}
